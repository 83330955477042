import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { titles, descriptions } from '../../const/metaData';
import {
  Wrapper,
  TextContainer,
  BoldText,
  Download,
} from '../../styles/commonStyles';
import { DOCUMENTS } from '../../const/documents';

const Terms: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.terms} description={descriptions.terms} />
      <Wrapper>
        <TextContainer>
          <h2>Hello!</h2>
          <BoldText>
            By using your Bó account, you’re agreeing to our terms and
            conditions, so it’s important you read them. If you need to refer
            back to them they’ll always be available in the app.
          </BoldText>
          <BoldText>1. About us</BoldText>
          <p>
            Bó is part of National Westminster Bank Plc (“NatWest”), which is
            registered in England and Wales (Registered Number 929027). Our
            registered office is 250 Bishopsgate, London EC2M 4AA.
          </p>
          <p>
            We’re authorised by the Prudential Regulation Authority and
            regulated by the Financial Conduct Authority and the Prudential
            Regulation Authority. NatWest is on the Financial Services Register
            (number 121878). You can check this at{' '}
            <a href="http://www.fca.org.uk/register" target="_blank">
              http://www.fca.org.uk/register
            </a>
            .
          </p>
          <BoldText>2. What you can and can’t use your Bó account for</BoldText>
          <p>
            Your Bó account is a personal bank account. This means you can’t use
            it for business purposes.
          </p>
          <BoldText>3. The laws protecting us both</BoldText>
          <p>
            English law applies to this agreement between us, unless you live in
            Scotland in which case it’s Scots law. If you ever need to take
            legal action against us you can do this in any UK court.
          </p>
          <BoldText>4. How you access your Bó account</BoldText>
          <p>
            You can access our services through the Bó app, your Bó debit card,
            at cash machines, or via any third party you’ve authorised (such as
            an account aggregator provider).
          </p>
          <BoldText>
            5. When we might not do what you ask us to, or limit what you can do
            with your account
          </BoldText>
          <p>We may do this if:</p>
          <ul>
            <li>you don’t have enough money in your account;</li>
            <li>
              we believe someone’s using your account without your permission;
            </li>
            <li>we suspect fraud or other criminal activity;</li>
            <li>we need to for legal or regulatory reasons;</li>
            <li>you’ve broken these terms;</li>
            <li>we don’t know what you want us to do;</li>
            <li>
              we believe you’ve not kept your security details and debit card
              details safe;
            </li>
            <li>a restriction applies to your account; or</li>
            <li>we believe it’s appropriate to protect your account.</li>
          </ul>
          <p>
            We’ll always let you know when we don’t do something you’ve asked us
            to or if we’ve limited how you can use your account. If we can,
            we’ll explain why we’ve done this, unless there’s a legal or
            security reason why we can’t.
          </p>
          <BoldText>6. Paying money into your Bó account</BoldText>
          <p>
            You can only add money to your account by electronic transfer using
            the Faster Payments Service. Any money transferred into your Bó
            account will usually be available to spend within a few minutes.
          </p>
          <BoldText>7. Using your Piggy Bank</BoldText>
          <p>
            You can take money from your Bó account and set it aside by moving
            it from your main balance into a Piggy Bank. If you do this, it will
            no longer appear within your main balance. This means:
          </p>
          <ul>
            <li>
              you won’t be able to spend it (unless you move the money back into
              your main balance);
            </li>
            <li>
              when you see your main balance, this won’t include any money in
              your Piggy Bank; and
            </li>
            <li>
              if you don’t have enough money in your main balance to make a
              withdrawal or payment, we’ll refuse the transaction (even if you
              have money in a Piggy Bank).
            </li>
          </ul>
          <BoldText>8. How to keep your account safe</BoldText>
          <p>
            To protect your account and keep your money safe, please make sure
            that you:
          </p>
          <ul>
            <li>
              keep your PIN, login details and passcodes safe. Don’t share these
              security details with anyone unless they’re someone you’ve
              authorised to view or access your account;
            </li>
            <li>
              don’t leave your phone unattended when you’re logged into your
              account;
            </li>
            <li>
              keep your Bó debit card safe and don’t let anyone else use it; and
            </li>
            <li>
              keep the software on your phone up to date including any
              anti-virus software.
            </li>
          </ul>
          <p>
            You’ll also receive instant notifications every time your card is
            used (if you’ve let us send you notifications). If there’s ever
            anything you don’t recognise, let us know as soon as you spot it.
          </p>
          <p>
            If you do give your login details or passcodes to another person or
            organisation such as an account aggregation provider, then we’re not
            responsible for what they do with them. If you decide to give them
            this information, this means that they’ll be able to see and do
            anything you can on your accounts.
          </p>
          <p>
            Fraudsters use all kinds of scams to trick you into parting with
            your money. At Bó we will never:
          </p>
          <ul>
            <li>
              ask you to give us or anyone else your full security details
              (including your debit card PIN, password or passcode);
            </li>
            <li>
              send someone to collect cash, your PIN or your debit card if
              you’re a victim of fraud; or
            </li>
            <li>
              ask you to transfer money to a new account for fraud reasons.
            </li>
          </ul>
          <BoldText>
            9. What you do if you lose your card or mobile, or someone knows
            your security details
          </BoldText>
          <p>
            If your card’s been lost or stolen or you think someone knows your
            passcode, cancel your card straight away. You can do this by going
            into ‘Card settings’ in the app. Once you’ve cancelled it we’ll send
            you a new one straight away.
          </p>
          <p>
            However if it’s your phone that’s been lost or stolen, get in touch
            with us through our website or contact{' '}
            <a href="mailto:bosupport@natwest.com">bosupport@natwest.com</a>.
          </p>
          <BoldText>
            10. Alerting you when we spot something that doesn’t look right
          </BoldText>
          <p>
            If we’re concerned about the safety of your account and money, we’ll
            contact you to let you know.
          </p>
          <BoldText>11. Statements</BoldText>
          <p>
            We’ll make a statement for you every month. You can download, save
            and print them yourself any time you want. Just click on the
            ‘Account’ tab in the app and then select ‘Statements’. It’s good to
            get into the habit of checking your statements regularly. If there’s
            ever a transaction you don’t recognise, let us know right away.
          </p>
          <BoldText>12. Contacting you</BoldText>
          <p>
            We’ll contact you in English by email, app notification, text or
            phone. For example, we may contact you to help you open your Bó
            account, if we think you’re stuck or need help signing up. Make sure
            you keep your contact details up to date in the app, so we can
            always get hold of you. It’s also up to you to check you have the
            correct hardware, software, operating system and browser so you can
            receive everything we send you.
          </p>
          <BoldText>13. Taking money out of your account</BoldText>
          <BoldText>Electronic payments to other bank accounts</BoldText>
          <p>
            As long as you have enough money in your account you can make
            electronic payments. When you make a payment, the money will leave
            your account immediately and will usually show up in the payee’s
            account within a few minutes (a payee is the individual, retailer or
            organisation you are making a payment to). We always try to make
            payments immediately, but in some circumstances it may take longer
            (for example if we suspect fraud)
          </p>
          <p>
            At the moment you can only send money in pounds sterling to accounts
            in the UK.
          </p>
          <BoldText>Cash withdrawals</BoldText>
          <p>
            As long as there’s enough money in your account, you can use your
            card to take money out at cash machines anywhere in the world. You
            can find out how much you can withdraw each day by tapping on the
            ‘Account’ tab in the Bó app and then selecting ‘Account limits’.
          </p>
          <BoldText>14. Using your Bó debit card to make payments</BoldText>
          <p>
            You can use your card to make payments in any currency anywhere in
            the world.
          </p>
          <p>
            If you make a payment in a foreign currency, we’ll convert it into
            pounds sterling using the Visa Payment Scheme Exchange Rate. To find
            out what these exchange rates are, just go to{' '}
            <a
              href="https://www.visa.co.uk/about-visa/visa-in-europe.html"
              target="_blank"
            >
              visaeurope.com.
            </a>
            .
          </p>
          <p>
            Sometimes, some retailers or service providers may accept payments
            you make using your card without checking with us first to see if
            you have enough money in your account. We can’t stop these payments
            leaving your account, so you may go overdrawn as a result.
          </p>
          <BoldText>15. Account fees and charges</BoldText>
          <p>
            Making cash withdrawals from ATMs in the UK, EU or anywhere else in
            the world is free.
          </p>
          <p>
            If you lose or damage your card, we’ll replace it free of charge the
            first two times. But if you lose or damage it again before it
            naturally expires, we’ll charge you £5 each time to replace it.
          </p>
          <p>
            If your card is stolen or you need the name changed (e.g. if you get
            married), we’ll always send you a new card free of charge.
          </p>
          <BoldText>
            16. How long it takes for a debit card payment to go through
          </BoldText>
          <p>
            We only take payments from your account when we receive the request
            from the payee’s bank (the bank used by the retailer, shop or store
            where you bought something).
          </p>
          <p>
            This means that sometimes there might be a delay between when you
            use your debit card and the money leaving your account. But as soon
            as we receive their request, we’ll make the payment within one
            business day. Our business days are Monday to Friday, excluding bank
            holidays in England and Wales.
          </p>
          <BoldText>17. Paying charges from your account</BoldText>
          <p>
            Any charges you need to pay will be taken directly from your
            account. There may be other charges for additional services not
            covered in this agreement, but we’ll always tell you about these
            charges before you sign up for those services.
          </p>
          <BoldText>18. Tax</BoldText>
          <p>
            If you owe any tax (or other charges) to an authority (for example,
            HMRC) in connection with your account, we may take this payment
            directly from your account.
          </p>
          <p>
            You confirm that you have been, and are, compliant with all tax
            declaration and reporting obligations relating to the money held in
            your Bó account and any income or gains your money produces.
          </p>
          <BoldText>19. What if something goes wrong?</BoldText>
          <BoldText>You don’t recognise a payment</BoldText>
          <p>
            If there’s a payment in your list of transactions you don’t
            recognise, either because it’s for the wrong amount, or you don’t
            recognise the payee (a payee is the individual, retailer or
            organisation the payment has been made to), tap on the transaction
            and follow the prompts. Our Support team will look into it right
            away.
          </p>
          <BoldText>
            An incorrect payment has been made to or from your account
          </BoldText>
          <BoldText>If it’s our mistake</BoldText>
          <p>
            If we pay money into your account, or take money out of your account
            by mistake, we’ll sort this out so your balance is what it should
            be.
          </p>
          <BoldText>If it’s your mistake</BoldText>
          <p>
            If you’re making a payment, you need to use the correct details for
            the payee (account number, sort code, date of payment and amount).
            If you don’t, we won’t take responsibility if the money doesn’t
            reach the right person or organisation.
          </p>
          <p>
            But if things do go wrong, we’ll try to get the money back for you.
            If we can’t, we won’t be able to refund you. However, we will ask
            the payee’s bank for the payee’s contact details so you can try to
            get the money back.
          </p>
          <p>
            <b>Always remember:</b> It’s the sort code and account number of an
            account that identifies it, not the account holder’s name.
          </p>
          <BoldText>If money is paid into your account by mistake</BoldText>
          <p>
            If someone makes a payment to you by mistake and their bank gets in
            touch with us to let us know, then we need to take the money from
            your account and hold it.
          </p>
          <p>
            We’ll then contact you to tell you what has happened. If you agree
            it was a mistake, or if we don’t hear back from you within 15
            business days after contacting you, we’ll return the money to the
            bank that contacted us about the payment. Our business days are
            Monday to Friday, not including bank holidays in England and Wales.
          </p>
          <p>
            If you tell us it wasn’t a mistake, we’ll return the money to you.
            However, we may need to share your contact details with the bank
            that contacted us about the payment.
          </p>
          <BoldText>
            20. Telling us about payments made without your permission
          </BoldText>
          <p>
            If a payment has been made from your account that you didn’t agree
            to, let us know as soon as possible. We’ll be able to refund you
            unless:
          </p>
          <ul>
            <li>
              you’ve acted fraudulently, in which case you won’t be refunded;
            </li>
            <li>
              you deliberately or with gross negligence failed to keep your card
              or security details safe. In which case we won’t refund you for
              any unauthorised payments made before you let us know; or
            </li>
            <li>
              you deliberately or with gross negligence failed to tell us as
              soon as you became aware that your card or security details were
              compromised, lost or stolen. In which case we won’t refund you for
              any unauthorised payments made before you let us know.
            </li>
          </ul>
          <p>
            Once you’ve told us about any payments that you didn’t agree to, if
            you’re entitled to a refund we’ll put the money back in your account
            by the end of the next business day. Our business days are Monday to
            Friday, not including bank holidays in England and Wales.
          </p>
          <p>
            But if the payment you’re concerned about was made more than 13
            months ago or we suspect fraud, it may take longer.
          </p>
          <BoldText>
            21. Payments taken from your account without you agreeing the amount
          </BoldText>
          <p>
            If you agree to let a retailer, organisation or business take a
            payment from your account without knowing how much the final amount
            will be (for example if you’re staying at a hotel or hiring a car)
            then we’ll refund you so long as:
          </p>
          <ul>
            <li>
              you didn’t know the exact amount you’d be charged when you
              authorised the payment;
            </li>
            <li>
              the amount was more than you could have reasonably expected
              (excluding any increases due to exchange rate fluctuations);
            </li>
            <li>
              the payment was made in the European Economic Area (this includes
              all the countries in the European Union plus Iceland,
              Liechtenstein and Norway); and
            </li>
            <li>
              you requested a refund within 8 weeks of the payment leaving your
              account.
            </li>
          </ul>
          <p>
            We won’t be able to refund you if you agreed to the payment directly
            with us and at least four weeks in advance:
          </p>
          <ul>
            <li>
              we or the payee (the individual, retailer or organisation you’re
              making a payment to) provided you with information about the
              payment; or
            </li>
            <li>we gave you information about the payment in the app.</li>
          </ul>
          <p>
            We may need you to give us more information to help us process your
            refund. We’ll refund you, or tell you why we can’t refund you,
            within 10 business days of us getting your request.
          </p>
          <p>
            If we asked you for further information about the refund, we’ll let
            you know the outcome 10 days after receiving the extra information.
          </p>
          <BoldText>22. Our general liability</BoldText>
          <p>
            We won’t be responsible for any losses caused by circumstances
            beyond our control such as strikes or extreme weather.
          </p>
          <BoldText>23. Changing these Terms and Conditions</BoldText>
          <p>
            If we introduce a new service (such as a new feature in the app), or
            lower or remove a fee, we can change these terms and conditions
            straight away. If we do this, we’ll let you know via the app within
            30 days.
          </p>
          <p>
            We can make any other changes where we have a valid reason to do so
            (for example, to reflect changes in how much it costs us to provide
            our services, to reflect changes in the law, changes to our systems
            or to make this agreement clearer to you). We will let you know
            about the change at least 60 days in advance via the app.
          </p>
          <p>
            If you don’t agree with the changes we have made, you have the right
            to close your account; otherwise we’ll assume you have agreed to the
            changes.
          </p>
          <BoldText>24. Transferring your account to another provider</BoldText>
          <p>
            We don’t think it’s likely, but we reserve the right to transfer
            your account (and your and our rights and responsibilities) to
            another provider in the future.
          </p>
          <p>
            This could happen if we decide to sell the business, or if we merge
            with another business, or a change in the law means we have to. We
            will only make a transfer like this if we believe you’ll be treated
            in a similar way afterwards to the way we’ve treated you as one of
            our customers.
          </p>
          <BoldText>25. Closing Your Bó Account</BoldText>
          <p>
            You can close your account whenever you want. Just transfer any
            money from your Bó account into another account and then contact our
            Support team. They will close your account for you. Just tap the
            ‘Support’ tab in the app to get in touch with them.
          </p>
          <BoldText>26. When we can close your account</BoldText>
          <p>
            We can close your account at any time by giving you at least 60 days
            notice. We can also close your account immediately if:
          </p>
          <ul>
            <li>
              we reasonably believe you gave us false information when you set
              up your account or that your account is being used, or looks like
              it’s going to be used, for something illegal;
            </li>
            <li>you threaten or are violent towards our staff;</li>
            <li>we have to for legal or regulatory reasons;</li>
            <li>you’re no longer resident in the UK;</li>
            <li>you’re declared bankrupt;</li>
            <li>
              you’ve entered into a voluntary arrangement with your creditors to
              repay your debts; or
            </li>
            <li>you’ve used your account for business purposes.</li>
          </ul>
          <BoldText>27. How do I make a complaint?</BoldText>
          <p>
            If you’re not happy with something, please let us know. You can do
            this via the ‘Support’ tab in the app and that’ll put you through to
            someone in our Support team. We take every complaint seriously and
            try to put things right as quickly as possible. We’ll always try to
            resolve your complaint straight away but sometimes we need a bit
            longer. Please allow us up to 8 weeks to resolve your complaint. If
            your complaint is payments related, we’ll resolve it within 35
            business days.
          </p>
          <p>
            If you’re not happy with how we’ve handled your complaint, you can
            contact the Financial Ombudsman Service. This is an independent
            organisation that helps to resolve complaints.
          </p>
          <BoldText>You can contact them:</BoldText>
          <ul>
            <li>
              <a href="https://www.financial-ombudsman.org.uk/" target="_blank">
                Financial-ombudsman.org.uk
              </a>
            </li>
            <li>0800 023 4567</li>
            <li>
              Financial Ombudsman Service Exchange Tower
              <br />
              London
              <br />
              E14 9SR
            </li>
          </ul>
          <p>
            You may also be able to use the European Commission’s online dispute
            resolution platform. You can get more information about this at{' '}
            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
              target="_blank"
            >
              ec.europa.eu/odr
            </a>
            .
          </p>
          <BoldText>28. Licence terms: Your right to use the Bó app</BoldText>
          <p>
            When you download Bó, we automatically give you permission to use
            the app, provided that you agree:
          </p>
          <ul>
            <li>
              you can only use the Bó app on a device registered to you or under
              your control. You’ll need to register a replacement device
              separately by following the instructions in the app when you log
              in;
            </li>
            <li>
              this permission is personal to you and you can’t pass it on to
              anyone else;
            </li>
            <li>
              we retain the right to allow other customers and third parties
              (such as account aggregation providers) to use the app;
            </li>
            <li>
              you must not crop, reproduce, alter, modify or adapt the Bó app or
              any part of it;
            </li>
            <li>
              you must not try to investigate and reproduce our code, or try to
              analyse or reverse engineer the Bó app or any part of it; and
            </li>
            <li>
              all ownership of the Bó app, including all intellectual property
              rights such as trade marks and copyright, remains with us. You
              must not remove or tamper with any copyright notice attached to or
              contained within the Bó app.
            </li>
          </ul>
          <p>
            The Licence includes the right to use any future updates to the Bó
            app that we make available to you. We’re solely responsible for the
            Bó app and its content.
          </p>
          <p>
            If you have any questions or need any help using Bó, please get in
            touch with our Support team by tapping the ‘Support’ tab in the app
            or emailing{' '}
            <a href="mailto:bosupport@natwest.com">bosupport@natwest.com</a>.
          </p>
          <p>
            We’ll assume that you’ve accepted the terms of this Licence by
            installing Bó on your phone and the Licence will run until your Bó
            account is closed and/or you delete the Bó app. If we terminate the
            Licence for any reason you must uninstall the Bó app and delete all
            copies of it.
          </p>
          <BoldText>29. Your personal information and privacy</BoldText>
          <p>
            We collect and use various categories of personal and financial
            information so that we can supply with you with Bo’s products and
            services and run our business.
          </p>
          <p>
            This includes basic personal information such as your name and
            contact details. It also includes information about your financial
            circumstances, your bank accounts and your transactions.
          </p>
          <p>
            For more information about how we collect, use and share your
            personal information, please read our full privacy notice, which is
            always available to download in the app or on our website.
          </p>
          <p>
            We also need to let you know that your information may be shared
            with and used by other Royal Bank of Scotland (RBS) Group companies.
            Bó is part of NatWest, which is part of the RBS Group.
          </p>
          <BoldText>
            We won’t share this information with anyone outside RBS except:
          </BoldText>
          <ul>
            <li>where we have your permission;</li>
            <li>where required for your product or service;</li>
            <li>
              with law enforcement agencies, judicial bodies, government
              entities, tax authorities or regulatory bodies around the world;
            </li>
            <li>
              with other banks, individuals or organisations so that we can help
              recover funds that have entered your account as a result of a
              payment sent in error by one of the above;
            </li>
            <li>
              with companies providing services to us, such as market analysis
              and benchmarking, correspondent banking, and agents and
              sub-contractors acting on our behalf, such as ID verification
              services;
            </li>
            <li>
              with other banks to help trace funds where you’re a victim of
              suspected financial crime and you have agreed for us to do so, or
              where we suspect funds have entered your account as a result of a
              financial crime;
            </li>
            <li>with debt collection agencies;</li>
            <li>with credit reference and fraud prevention agencies;</li>
            <li>
              with external guarantors or other companies that provide you with
              benefits or services (such as insurance cover) associated with
              your product or service;
            </li>
            <li>
              where required for a proposed sale, reorganisation, transfer,
              financial arrangement, asset disposal or other transaction
              relating to our business and/or assets held by our business;
            </li>
            <li>
              where necessary for our legitimate interests (e.g. to help us
              provide and improve our products and services to make them better
              for you) or those of a third party, and it is not inconsistent
              with the purposes listed above.
            </li>
          </ul>
          <p>
            If you ask us to, we’ll share your information with any other
            financial services company that is registered under Open Banking and
            that provides you with account information or payment services.
          </p>
          <p>
            If you ask them to provide you with account information or payment
            services, you’re then allowing them to access information relating
            to your account. We’re not responsible for how they use your account
            information. How they do this, will be set out in their agreement
            with you and in any privacy statement they provide you with.
          </p>
          <br />
          <h2>Documents</h2>
          <Download
            href={DOCUMENTS.terms.v1_1}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </Download>
          <Download
            href={DOCUMENTS.fee_information.v1}
            target="_blank"
            rel="noopener noreferrer"
          >
            Fee Information
          </Download>
          <Download
            href={DOCUMENTS.glossary_of_terms.v1}
            target="_blank"
            rel="noopener noreferrer"
          >
            Glossary of Terms
          </Download>
        </TextContainer>
      </Wrapper>
    </Layout>
  );
};

export default Terms;
